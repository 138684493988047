export default [
    {
        header: 'Financeiro',
        icon: 'DollarSignIcon',
        children: [
            {
                title: 'Receitas',
                icon: 'TrendingUpIcon',
                route: 'financeiro-receitas',
                permissao: 4

            },

            {
                title:'Despesas',
                icon: 'TrendingDownIcon',
                route: 'financeiro-despesas',
                permissao: 4

            },

            {
                title: 'Plano de Contas',
                route: 'plano',
                icon: 'AlignCenterIcon',
                permissao: 4
            },

            {
                title: 'Conta Bancária',
                route: 'financeiro-contas',
                icon: 'CreditCardIcon',
                permissao: 4
            },

            {
                title: 'Pedidos Retirada',
                route: 'financeiro-pedidos',
                icon: 'MailIcon',
                permissao: 3
            },

            {
                title: 'D.R.E.',
                route: 'financeiro-dre',
                icon: 'BarChartIcon',
                permissao: 4
            },

            {
                title: 'Nota Fiscal',
                route: 'financeiro-nfse',
                icon: 'FileTextIcon',
                permissao: 4
            },



        ],
    },
]
