import financeiro from './financeiro'
import cadastros from './cadastros'
import administracao from './administracao'
import mensagens from './mensagens'

export default [

  {
    header: 'Pages',
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'home',
  },
  ...financeiro,...cadastros,...administracao,...mensagens,
]
