export default [
    {
        header: 'Administração',
        icon: 'MonitorIcon',
        children: [

            {
                title: 'Clientes',
                route: 'adm-clientes',
                icon: 'UsersIcon',
                permissao: 1
            },
            {
                title: 'Cadastro Teste',
                route: 'adm-cadastro-teste',
                icon: 'Edit3Icon',
                permissao: 1
            },
            {
                title: 'Usuários',
                route: 'adm-usuarios-cliente',
                icon: 'UsersIcon',
                permissao: 1
            },


        ],
    },
]
