export default [
    {
        header: 'Cadastros',
        icon: 'Edit3Icon',
        children: [

            {
                title: 'Sistemas',
                route: 'cadastros-sistemas',
                icon: 'UsersIcon',
                permissao: 5
            },

            {
                title: 'Fornecedores',
                route: 'cadastros-fornecedores',
                icon: 'ShoppingCartIcon',
                permissao: 4
            },

            {
                title: 'Assinaturas',
                route: 'cadastros-assinaturas',
                icon: 'FileIcon',
                permissao: 5
            },

            {
                title: 'Cupons',
                route: 'cadastros-cupons',
                icon: 'TagIcon',
                permissao: 3
            },


        ],
    },
]
